import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import logoImg from "../img/NewLogo.jpg";
import { Error, Spacer } from '../styled-components/AuthForm';

import { authenticationService } from '../_services/authentication.service';

function Login(props) {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")


    useEffect(() => {
        if (authenticationService.currentUserValue) { 
            props.history.push('/');
        }
    })

    function handleSubmit(e) {
        e.preventDefault()
        authenticationService.login(username, password)
        //.then 307 == Reset redirect
        .then(user => {
            console.log(user)
                if(user.error_code === 307) {
                    <Redirect to="/Reset" />
                } else {
                    const { from } = props.location.state || { from: { pathname: "/" } };
                    props.history.push(from);
                }
            },
            error => {
                setIsError(true);
                setErrorMsg(error);
            }
        );
    }


    return (
        <div className="container center">
            <form className="form-signin" onSubmit={handleSubmit}>
                <img className="mb-4" src={logoImg} alt="DKAVS Logo" width="72"/>
                <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                <div className="form-floating">
                    <label htmlFor="floatingInput">Email address</label>
                    <input type="username" className="form-control" id="floatingInput" value={username} onChange={e => {
                        setUserName(e.target.value);
                    }} placeholder="name@amazon.com" />
                </div>

                <div className="form-floating">
                    <label htmlFor="floatingPassword">Password</label>
                    <input type="password" className="form-control" id="floatingPassword" value={password} onChange={e => {
                        setPassword(e.target.value);
                    }} placeholder="Password" />
                </div>
                <Spacer mb={3} />
                <button className="btn btn-primary" >Sign In</button>
                <p className="mt-5 mb-3 text-muted">© DKAVS 2021</p>
            </form> 
            { isError &&<Error>{errorMsg}</Error>} 
        </div>
    );
}

export default Login;
