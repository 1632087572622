import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import { history } from '../_helpers/history';

const ROOT_API = process.env.REACT_APP_API_URL

function Job() {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [jobNo, setJobNo] = useState("");
    var { id } = useParams()

    function search () {
        history.push(`/job/${jobNo}`);
    }

    useEffect(() => {
        // console.log(id)
        fetch (`${ROOT_API}/job/${id}`, {
            method: 'POST',
            body: JSON.stringify({
                id
            }),
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json; charset=UTF-8",
                "Connection": "close",
                // "session-id": JSON.parse(localStorage.getItem('currentUser').session_id)
            }
        }).then(result => {
            console.log(result)
        }).catch(err => {
            setIsError(true);
            setErrorMsg('Internal Error please contact your Administrator')
        })
    }, [id])
    
    return (            
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 py-5">
            <div className="row col-12 center">
                <div className="col-md-4 col-md-offset-4 center"> 
                    <h2>Find A Job</h2>
                    <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" placeholder="Find Job" aria-label="Search" value={jobNo} onChange={e => {
                                    setJobNo(e.target.value);}}/>
                        <button className="btn btn-outline-success my-2 my-sm-0" onClick={search}>Search</button>
                    </form>
                </div>
            </div>
            
            <div className="row col-12 center py-5">
                <div className="col-md-4 col-md-offset-4"> 
                    <p><strong>ID:</strong> {id}</p>
                </div>
            </div>

            { isError && {errorMsg}}
        </main>
    );
}

export default Job