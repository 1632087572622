import React, { useState } from "react";
import Popup from "../_components/Popup"; 
import {Error, Spacer} from '../styled-components/RequestForm';

import "./dashboard.css"

const API_URL = process.env.REACT_APP_API_URL

function RequestForm(props) {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");    
    const [isOpen, setIsOpen] = useState(false);
    const [messagePopup, setMessagePopup] = useState("");

    const [policyNo, setPolicyNo] = useState("");
    const [repairReference, setRepairReference] = useState("");
    const [policyExpiry, setPolicyExpiry] = useState("");
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [repairUntil, setRepairUntil] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [town, setTown] = useState("");
    const [county, setCounty] = useState("");
    const [postCode, setPostCode] = useState("");
    const [homeTel, setHomeTel] = useState("");
    const [mobileTel, setMobileTel] = useState("");
    const [email, setemail] = useState("");
    const [productType, setProductType] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [model, setModel] = useState("");
    const [fault, setFault] = useState("");    
    const [riskType, setRisk] = useState("");    
    const [additional, setAdditional] = useState(""); 

    function postForm() {
        if(policyNo === "" || repairReference === "" || policyExpiry === "" ||  purchasePrice === "" || repairUntil < 5 || lastName === "" || firstName === "" ||  address1 === "" ||  town === "" ||  postCode === "" ||  productType === "" ||  manufacturer === "" ||  model === "" ||  fault === "" ||  riskType === "") {
                setIsError(true)
                setErrorMsg('Please fill in all necessary fields')
        } else {
            fetch(`${API_URL}/jobs`, {
                method: 'POST',
                body: JSON.stringify({
                    policyNo,repairReference,policyExpiry, purchasePrice, customerName: firstName + lastName, 
                    address1, address2, town, county, postCode, homeTel, mobileTel, email,
                    productType, manufacturer, model, fault, riskType, additional
                }),
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "Accept": "application/json; charset=UTF-8",
                    "Connection": "close",
                    "session-id": JSON.parse(localStorage.getItem('currentUser')).session_id
                }
            }).then(result => {
                if (result.status === 201){
                    return result.json().then(json => {
                        setMessagePopup("Job Number: " + repairReference + " has been submitted")
                        setPolicyNo("");
                        setRepairReference("");
                        setPolicyExpiry("");
                        setPurchasePrice("");
                        setRepairUntil("");
                        setFirstName("");
                        setLastName("");
                        setAddress1("");
                        setAddress2("");
                        setTown("");
                        setCounty("");
                        setPostCode("");
                        setHomeTel("");
                        setMobileTel("");
                        setemail("");
                        setProductType("");
                        setManufacturer("");
                        setModel("");
                        setFault("");
                        setRisk("");
                        setAdditional("");
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        setIsOpen(!isOpen);
                    })
                } else {
                    setIsError(true);
                    setErrorMsg(result.json().error_message)
                }
            }).catch(err => {
                setIsError(true);
                setErrorMsg("Internal Error please contact info@superbase.com")
            })
        }
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    return(
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 mb-2">
            <h4><strong>Duplicate repair reference numbers will be rejected, please obtain a new number and try again!</strong></h4>
            <Spacer mb={4} />
                
            <form onSubmit={postForm}>
                <h3>Policy Details</h3>
                <div className="form-row">
                    <div className="col-md-3 mb-3">
                        <label>Policy No. (*)</label>
                        <input className="form-control"  type="text"  value={policyNo} onChange={e => {
                            setPolicyNo(e.target.value);
                        }} placeholder="Policy Number (*)" maxLength="" required/>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label>Repair Reference (*)</label>
                        <input className="form-control"  type="text"  value={repairReference} onChange={e => {
                            setRepairReference(e.target.value);
                        }} placeholder="Repair Reference No. (*)" maxLength=""/>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label>Policy Expiry (*)</label>
                        <input className="form-control"  type="date"  value={policyExpiry} onChange={e => {
                            setPolicyExpiry(e.target.value);
                        }} placeholder="Policy Expiry (*)" />
                    </div>
                    <div className="col-md-3 mb-3">
                        <label>Purchase Price (*)</label>
                        <div className="input-group-prepend">
                            <div className="input-group-text">£</div>
                            <input className="form-control"  type="number" step="any" value={purchasePrice} onChange={e => {
                                setPurchasePrice(e.target.value);
                            }} />
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label>Repair Up To (*)</label>
                        <div className="input-group-prepend">
                            <div className="input-group-text">£</div>
                            <input className="form-control"  type="number" step="any" value={repairUntil} onChange={e => {
                                setRepairUntil(e.target.value);
                            }} />
                        </div>
                    </div>
                </div>
                <h3>Customer Details</h3>
                <div className="form-row">
                    <div className="col-12 col-md-7">
                        <label>Customer First Name (*)</label>
                        <input className="form-control"  type="text"  value={firstName} onChange={e => {
                            setFirstName(e.target.value);
                        }} placeholder="Customer First Name" maxLength="50"/>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Customer Last Name (*)</label>
                        <input className="form-control"  type="text"  value={lastName} onChange={e => {
                            setLastName(e.target.value);
                        }} placeholder="Customer Last Name" maxLength="70"/>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Address 1 (*)</label>
                        <input className="form-control"  type="text"  value={address1} onChange={e => {
                            setAddress1(e.target.value);
                        }} placeholder="Address 1" maxLength="150"/>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Address 2</label>
                        <input className="form-control"  type="text"  value={address2} onChange={e => {
                            setAddress2(e.target.value);
                        }} placeholder="Address 2" maxLength="100"/>
                    </div>

                    <div className="col-12 col-md-6">
                        <label>Town (*)</label>
                        <input className="form-control"  type="text" value={town} onChange={e => {
                            setTown(e.target.value);
                        }} placeholder="Town" maxLength="150"/>
                    </div>
                    <div className="col-md-4">
                        <label>County</label>
                        <input className="form-control"  type="text"  value={county} onChange={e => {
                            setCounty(e.target.value);
                        }} placeholder="County" maxLength="100"/>
                    </div>
                    <div className="col-md-2">
                        <label>Post Code (*)</label>
                        <input className="form-control"  type="text" value={postCode} onChange={e => {
                            setPostCode(e.target.value);
                        }} placeholder="PostCode" maxLength="9"/>
                    </div>

                    <div className="col-md-4">
                        <label>Home Telephone</label>
                        <input className="form-control"  type="text"  value={homeTel} onChange={e => {
                            setHomeTel(e.target.value);
                        }} placeholder="Home Telephone" maxLength="12"/>
                    </div>
                    <div className="col-md-4">
                        <label>Mobile Telephone</label>
                        <input className="form-control"  type="text" value={mobileTel} onChange={e => {
                            setMobileTel(e.target.value);
                        }} placeholder="Mobile Telephone" maxLength="12"/>
                    </div>
                    <div className="col-md-8">
                        <label>Email Address</label>
                        <input className="form-control"  type="email" value={email} onChange={e => {
                            setemail(e.target.value);
                        }} placeholder="Email Address" maxLength="100"/>
                    </div>                   
                </div>
                <h3>Product Details</h3>
                <div className="form-row">
                    <div className="col-12 col-md-7">
                        <label>Product Type (*)</label>
                        <select className="custom-select" type="text"  value={productType} onChange={e => {
                            setProductType(e.target.value);
                        }}>
                            <option defaultValue value="">Product Type</option>
                            <option value="camera">Camera</option>
                            <option value="headphones">Headphones</option>
                            <option value="tv">TV</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Manufacturer (*)</label>
                        <select className="custom-select" type="text"  value={manufacturer} onChange={e => {
                            setManufacturer(e.target.value);
                        }}>
                            <option defaultValue value="">Select Manufacturer</option>
                            <option value="apple">Apple</option>
                            <option value="bose">Bose</option>
                            <option value="bando">B&O</option>
                            <option value="bandw">B&W</option>
                            <option value="beats">Beats</option>
                            <option value="canon">Canon</option>
                            <option value="fujifilm">Fujifilm</option>
                            <option value="gopro">Go-Pro</option>
                            <option value="insta360">Insta360</option>
                            <option value="jabra">Jabra</option>
                            <option value="kandao">Kandao</option>
                            <option value="nikon">Nikon</option>
                            <option value="olympus">Olympus</option>
                            <option value="panasonic">Panasonic</option>
                            <option value="sennheiser">Sennheiser</option>
                            <option value="sony">Sony</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Model (*)</label>
                        <input className="form-control"  type="text"  value={model} onChange={e => {
                            setModel(e.target.value);
                        }} placeholder="Model" maxLength="150"/>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Fault Reported (*)</label>
                        <input className="form-control"  type="text"  value={fault} onChange={e => {
                            setFault(e.target.value);
                        }} placeholder="Fault Reported" maxLength="300"/>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>Risk Type (*)</label>
                        <select className="form-control" type="text"  value={riskType} onChange={e => {
                            setRisk(e.target.value);
                        }}>
                            <option defaultValue value="">Select Risk Type</option>
                            <option value="AD">AD</option>
                            <option value="Breakdown">BD</option>
                        </select>
                    </div>
                    <div className="col-12">
                        <label>Additional Information</label>
                        <input className="form-control"  type="text" value={additional} onChange={e => {
                            setAdditional(e.target.value);
                        }} placeholder="Additional Information" /> 
                    </div>
                </div>
                
                <Spacer mb={3} />
                
                { isError && <Error>{errorMsg}</Error>}
                
                <p>(*) is a mandatory field</p>

                <button className="btn btn-primary">Submit</button>
            </form>
            {isOpen && <Popup content={<>
                    <b>Report Submitted</b>
                    <p>{messagePopup}</p>
                </>} handleClose={togglePopup}
                />}
        </main>
        
    );
}

export default RequestForm;
