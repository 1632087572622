import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Route} from "react-router-dom";

import { history } from './_helpers/history';
import { Role } from './_helpers/role'
import { authenticationService } from './_services/authentication.service';
import { PrivateRoute } from './_components/PrivateRoute';

// Pages
import Admin from "./pages/Admin";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import RequestForm from "./pages/RequestForm";
import Reset from "./pages/Reset"
import Status from "./pages/Status"
import Job from "./pages/Job"

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { BarChart2, Home, LogOut, Plus, Search, User } from "react-feather";
// import './pages/dashboard.css'

function App() {
  const [currentUser, setCurrentUser] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  

  useEffect(() => {
    authenticationService.currentUser.subscribe(x =>
      {
        setCurrentUser(x)
        setLoggedIn(x && false)
        setIsAdmin(x && x.role === Role.Admin)
      })
    })

  function logout() {
    authenticationService.logout();
    history.push('/login');
  }

  return (
    <Router history={history}>
      {loggedIn !== false ? (<div><Route path="/" component={Login} /></div>) : (<></>)}
      {currentUser &&
        <div>
          <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
            <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="/">DKAVS Amazon Protect</a>
            <input className="form-control-dark form-control w-100" type="text" placeholder="Search" aria-label="Search" />
            <ul className="navbar-nav px-3">
              <li className="nav-item text-nowrap">
                <Link className="nav-link" onClick={logout}>Log Out <LogOut width="16px" height="16px" /></Link>
              </li>
            </ul>
          </nav>
      
          <div className="container-fluid">
            <div className="row">
              <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                  <ul className="nav nav-pills flex-column mb-auto">
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><Home width="24px" height="24px"/> Dashboard</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/request"><Plus width="24px" height="24px"/> New Repair</Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/search"><Search width="24px" height="24px"/> Find Repair Status</Link>
                    </li> */}
                    {isAdmin && 
                    <><hr /><li className="nav-item">
                      <Link to="/admin" className="nav-link"><User width="24px" height="24px" /> Admin</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><BarChart2 width="24px" height="24px" />Reports</Link>
                    </li></>}
                    
                  </ul>
      
                  {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Saved reports</span>
                    <a className="d-flex align-items-center text-muted" href="#">
                      <span data-feather="plus-circle"></span>
                    </a>
                  </h6>
                  <ul className="nav flex-column mb-2">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Current month
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Last quarter
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Social engagement
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Year-end sale
                      </a>
                    </li>
                  </ul> */}
                </div>
              </nav>
              {/* Open Routes */}
              {/* Default Routes */}
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute path="/admin" roles={[Role.Admin]} component={Admin} />
              <PrivateRoute path="/reset" component={Reset} />
              {/* Amazon Specific */}
              <PrivateRoute path="/request" component={RequestForm} />
              <PrivateRoute path="/search" component={Status} />
              <PrivateRoute path="/job/:id" component={Job}/> 
            </div>

            
          </div>
          <footer className="footer container-fluid w-100 py-3">Powered by <a href="https://www.superbase.com/">Superbase.com</a></footer>
        </div>
      }     
    </Router>   
  );
}
export default App;
