import { authHeader } from '../_helpers/auth-header';
import { handleResponse } from  '../_helpers/handle-response'

const apiUrl = process.env.API_URL

export const userService = {
    getAll,
    getById
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}