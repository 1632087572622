import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import logoImg from "../img/NewLogo.jpg";
import { Card, Logo, Form, Error, Spacer } from '../styled-components/AuthForm';

function Reset() {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    

    function postReset() {
        if (password === passwordConfirm && passwordConfirm !== ""){
            fetch('/php/frontend.php?path=/reset', {
                method: 'POST',
                body: JSON.stringify({
                    password
                }),
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "Accept": "application/json; charset=UTF-8",
                    "Connection": "close"
                }
            }).then(result => {
                if (result.status === 200){
                    return result.json().then(json => {
                        return <Redirect to="/login" />;
                    })
                } else {
                    return result.json().then(json => {
                        setIsError(true);
                        setErrorMsg(json.error_message)
                    })
                }
            }).catch(err => {
                setIsError(true);
                setErrorMsg("Internal Error please contact info@superbase.com")
            })
        } else {
            setIsError(true);
            setErrorMsg("Passwords have to be identical");
        }
    }

    return (
        <Card>
            <Logo src={logoImg} />
            <Form onSubmit={postReset}>
                <label>Enter Password</label>
                <input className="form-control"  type="password" value={password} onChange={e => {
                    setPassword(e.target.value);
                }} placeholder="password" />
                <label>Enter Password Again</label>
                <input className="form-control"  type="password" value={passwordConfirm} onChange={e => {
                    setPasswordConfirm(e.target.value);
                }} placeholder="password" />
                <Spacer mb={3} />
                <button className="btn btn-primary">Update Password</button>
            </Form>
            <Spacer mb={3} />
            { isError &&<Error>{errorMsg}</Error>}
        </Card>
    );
}

export default Reset;